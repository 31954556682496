import React from "react"
import styles from "../static/bloghead.module.css"
import Header from "../components/header"
import Heading from "../components/heading"
import Nav from "../components/nav"
import { Link } from "gatsby"



export default function PostOne() {

  return (
    <div>
        <Header>
            <Heading title='Blog' href='/' linkText='Home' />
            <Nav />
        </Header>
    <div className={styles.page}>
    	<Link to="/blog" className={styles.back}>Back to blog</Link>
    	<h1 className={styles.title}>The Sweet Spot of Agreeableness</h1>
    	<p className={styles.post}>
    		It’s important to find a good degree of agreeableness. It’s a broad spectrum – on one 
    		end, there are people who consistently do things they don’t really want to in order to appease
    		others. In contrast to this are the being-disagreeable-brings-me-no-shame folk. They probably are 
            very efficient time managaers, but often assholes.

    		<br></br><br></br>

    		There are many situations when one's agreeableness comes into play. One example is borrowing things,
    		especially sharing bites of food. People are typically either totally fine sharing their food (the "Yeses"),
    		or not fine at all (the "No's"). This leads to three different combinations at the restaurant table.

    		<br></br><br></br>

    		1) Two Yeses: A love-fest of trading bites where each party repeatedly states, "Wow, that's really good."
  			<br></br>
			2)	Two No's: A quiet polite meal where no one exchanges spit.
			<br></br>
			3)	A Yes asks for a bite of a No's meal. The worst case here is a sandwich or burger - something 
            that you really have to get your mouth all over to eat. In this scenario I usually grit my teeth and 
            offer up the bite so as to not be an a-hole. That is, I choose agreeableness. 
            I acknowledge that it's really not a big deal (not to say I don't look away and try to think of anything but the alien saliva
                about to enter my mouth), and in this case suffering through the bite is, in my opinion,
            better than turning down an enthusiastic Yes friend's request.

			<br></br><br></br>

			Another scenario is asking people for help. You can't always ask people to do things for you,
            but there are instances when someone can solve your problem a lot faster than you can, and it 
            seems reasonable to ask. Where is the line drawn though, between annoying someone and asking them for
            a simple favor to maximize efficiency? For example, constantly asking your smart friend for help on 
            homework is an annoyance. Asking a classmate for a summary of what you missed in 
            lecture is totally OK, and they would be in the wrong
            for being annoyed. So the line is somewhere within there. 

            
    		
    		<br></br><br></br>

    		Another debate – sending food back. It definitely depends on how badly the meal was messed up, 
    		but, for example, if they put onions in your omelet and you didn’t want onions, should you send
    		 it back? You totally have a right to do so, but I consider this a little too disagreeable. 
    		 Just pick out the onions (or don't be a ten-year-old and just like onions in the first place).
    		  Some people would totally disagree – it’s on the restaurant to get your meal right.
    		   But let’s say your meal comes out cold. I might just eat it because I’m too 
    		   agreeable and want to avoid confrontation and annoying the waiter and / or chef 
    		   (but again, I’m probably wrongly assuming they’d be annoyed). Here is where I think 
    		   I want to be more disagreeable, and make the sacrifice of bothering someone else to get what I want.

    		  <br></br><br></br>

    		Some situations in which to choose agreeableness: you run into someone you really dislike out in public.
            You probably don’t want to talk to them and really just 
    		want to give them a nice jab to the throat, but instead of telling them this you 
    		(hopefully) recognize their dignity as a human and sacrifice a few minutes of your 
    		time to engage in small talk with them. 
			Wearing a mask is another a good example. Even if you don't believe in the science
            behind it, it's such a small inconvenience that no one should take the disagreeable route.

    	</p>
    </div>
    </div>
  )
 }